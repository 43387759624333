$textColor: #39414F;
$darkTextColor: #2E3A48;
$subtextColor: #707070;
$lightTextColor: #9DA3AD;
$successColor: #3C9E94;
$whiteColor: rgb(243, 247, 249);

.footer {
	a {
		color: $whiteColor;

		&:hover {
			color: darken($whiteColor, 10%);
		}
	}
}