body, html {
	background-color: #F8FAFC;
}

.container {
	// width: 50rem;
	margin: 0 auto;
	margin-top: 1rem;
	background-color: #FFF;

	border: 1px solid #E1E4E8;

	border-radius: 1rem;

	padding: 0rem;	
}

.receiptCard {
	padding: 0rem;
}

.cardHeader {
	//padding: 1rem;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;

	background-color: #495BB4;

	text-align: center;

	img {
		border-radius: inherit;
		width: 50%;
	}

	div {
		text-align: initial;
		background-color: #FFF;
	}
}

.footer {
	background-color: #F9FBFC;
	min-height: 4rem;
	width: 100%;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	padding: 1rem;
}