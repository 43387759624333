@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Raleway:wght@400;700&display=swap');

@import '~@drewbot/sass-flexbox-grid/public/sass-flexbox/main.min.css';

@import './typography.scss';
@import './Shadows.scss';
@import './themify-icons.css';

$textColor: #474747;
$subtextColor: #707070;


html,
body {
	padding: 0;
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont,  Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
		/* font-family: "Futura", sans-serif;   */

		font-family: "Lato", sans-serif;

		/*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
		*/
		font-weight: 400;

	min-height: 100vh;

	color: $textColor;
}

a {
	text-decoration: none;
}

* {
	box-sizing: border-box;
}


.center {
	text-align: center;
}

.layout {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	min-height: 100vh;
}

.reward-icon {
	// line-height: 12px;

	i {
		font-size: 1.5rem;
		vertical-align: sub;
	}
}


.donate-rewards {
	div {
		display: flex;
		margin-bottom: 2rem;
	}

	.reward-text {
		line-height: 1.5rem;
		vertical-align: middle;

		div {
			display: flex;
		}

	}
}


hr {
	border-color: #E5E6EA;
	border-style: solid;
}