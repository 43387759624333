// @import '../../node_modules/materialize-css/sass/components/color-variables';
// @import '../../node_modules/materialize-css/sass/components/color-classes';

.stripeButton {
	// @extend .blue;


	color: #FFF;
	background-color: #4D9DE0;


	height: 48px;
	width: 100%;
	padding: 0;
	
	border: 0;
	outline: none;
	// border-radius: 6px;
	cursor: pointer;
	transition: all .2s ease,box-shadow .08s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	border-radius: inherit;

	&:hover {
		background-color: darken(#4D9DE0, 5%);
	}


	// &:hover {
	// 	@extend .blue, .lighten-1;
	// }

	// &:active {
	// 	@extend .blue, .darken-1;
	// }
}