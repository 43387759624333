.container {
	width: 100%;
	display: grid; 
	grid-template-columns: 1fr 1fr 2fr; 
	grid-template-rows: 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
		"left left right"; 

	height: 4rem;

	border-bottom-left-radius: 1rem;
	
}

.left {
	grid-area: left;
	border-bottom-left-radius: 1rem;
}

.right {
	grid-area: right;
	border-bottom-left-radius: 1rem;
}
  

.button {
	width: 100%;
	height: 100%;

	background-color: #3B4255;
	color: white;
	outline: none;
	appearance: none;
	border: none;
	font-size: 1em;

	border-left: 1px solid lighten(#3B4255, 25%);

	border-bottom-right-radius: 1rem;


	&:hover {
		background-color: #2A2F3C;
		cursor: pointer;
	}

}