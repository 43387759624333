.sidebar {
	flex-basis: 16rem;
	flex-grow: 1;

	border-right: 1px solid #ebebeb;
}

.sidebarContent {
	margin-top: 16px;

	// display: flex;
	// position: fixed;
    // flex-direction: column;
    // left: 0%;
    // top: 0%;
    // flex-grow: 1;
    // width: 16rem;
    // flex-basis: 16rem;
}

.layout {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	min-height: 100vh;
}


.content {
	flex-basis: 0;
	flex-grow: 999;
	min-width: 50%;

	padding: 1rem;

	&.noPadding {
		padding: 0rem;
	}
}

.header {
	background-color: #fbfbfc;
	border-bottom: 1px solid #e9ecef;
}