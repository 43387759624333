.header {
	height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    * {
        color: white;
    }

}