@import './keyframesMixin.scss';


$cartWidth: 20rem;
$cartHeight: 40rem;
$fabSize: 5rem;
$fabMargin: 2rem;


@include keyframes( wobble ) {
	25% {
		transform: rotate(15deg);
	}

	50% {
		transform: rotate(-30deg);
	}

	75% {
		transform: rotate(5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

.cartWrapper {
	position: fixed;
	bottom: $fabMargin;
	right: $fabMargin;
}

.header {
	background-color: #495BB4;
	width: 100%;
	padding: 1rem;
	grid-area: header;

	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}



.headerInner {
	* {
		color: #FFF;
		//display: flex;
	}

	span {
		display: flex;
	}

	h3 {
		margin-right: 2rem;
		font-weight: normal;
	}

	margin-left: 1rem
}

.cartInner {
	height: $cartHeight;
	width: $cartWidth;

	background-color: white;

	position: relative;
	z-index: 0;

	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;

	right: $fabSize/2;
	top: $fabSize/2;

	//overflow-y: scroll;

	border-radius: 1rem;
	visibility: hidden;

	display: grid; 
	grid-template-columns: 1fr; 
	grid-template-rows: 0.2fr 2fr 0.2fr; 
	gap: 0px 0px; 
	grid-template-areas: 
		"header"
		"content"
		"footer"; 
	//width: 100%; 

	display: none;


	&.isOpen {
		display: grid;
		right: $fabSize/2;
		top: $fabSize/2;
		opacity: 1;
		transition: visibility 0s, opacity 0.5s linear;
		visibility: visible;
	}
}

.cartButton {
	user-select: none;
	width: $fabSize;
	height: $fabSize;

	background-color: #4D9DE0;

	position: relative;
	
	float: right;

	border-radius: 50%;

	z-index: 1;

	cursor: pointer;

	display: flex;
    justify-content: center;
    align-items: center;

	font-size: 24px;

	a {
		color: #FFF;
		width: 50%;
		height: 50%;
		.icons {
			position: relative;

			display: flex;
			justify-content: center;
			align-items: center;
	
			margin: 0 2.5rem 0 0;
			
			width: 100%;
			height: 100%;

			&.animated {
				animation: wobble 1s 1;
			}

			i {
				position: absolute;
				top: 0.5rem;
				left: 0.5rem;
	
				display: block;
			}

			.iconDefault {
				transition: opacity .3s, transform .3s;
			}
	
			.iconHover {
				transition: opacity .3s, transform .3s;
				transform: rotate(-180deg) scale(.5);
				opacity: 0;
			}

			&:hover {
				color: #FFF;
				transform: scale(1.2);
			}

			&.isOpen {
				.iconHover {
					transform: rotate(0deg) scale(1);
					opacity: 1;
				}
				.iconDefault {
					transform: rotate(180deg) scale(.5);
					opacity: 0;
				}
			}
		}
	}
}

.paymentButton {
	height: 100%;
}

.content {
	grid-area: content;
	height: 100%;
	overflow-y: scroll;

	display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.footer {
	grid-area: footer;
	display: flex;

	//border-bottom-right-radius: inherit;

	align-items: flex-end;

	border-radius: 1rem;
	width: 100%;

	div {
		div {
			&:first-child {
				border-bottom-left-radius: 1rem;
			}

			&:last-child {
				border-bottom-right-radius: 1rem;
			}
		}
	}
}

.checkoutButtonCol {
	padding: 0;
}

.paypalButtonCol {
	//background-color: #ffc439;
	height: 48px;
	div {
		iframe {
			border-bottom-left-radius: 1rem;
		}
	}
}

.stripeButtonCol {
	height: 48px;
	// background-color: #4D9DE0;
}