@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;700&display=swap');

$textColor: #39414F;
$darkTextColor: #2E3A48;
$subtextColor: #707070;
$lightTextColor: #9DA3AD;
$successColor: #3C9E94;
$whiteColor: rgb(243, 247, 249);

$font-sizes: (
	h1: 4rem,
	h2: 3rem,
	h3: 2.25rem,
	h4: 1.875rem,
	h5: 1.5rem,
	h6: 1.25rem
);

@mixin textColors {
	color: $textColor;

	&.subtext {
		color: $subtextColor;
	}

	&.light {
		color: $lightTextColor;
	}

	&.dark {
		color: $darkTextColor;
	}

	&.success {
		color: $successColor;
	}

	&.white {
		color: $whiteColor;
	}
}

@mixin fontWeight {
	&.thin {
		font-weight: 300;
	}

	font-weight: normal;

	&.thick {
		font-weight: bold;
	}
}

@each $heading, $size in $font-sizes {
	#{$heading} {
		font-size: $size;
		font-family: inherit;
		font-weight: inherit;

		margin-top: 0;
   		margin-bottom: .5rem;

		@include textColors();
		@include fontWeight();
	}
} 

p {
	// font-size: 1rem;
	// margin-top: 0;
	// margin-bottom: 1rem;
	// line-height: 1.2;

	@include textColors();
	@include fontWeight();
}

span, tr, th, td {
	@include textColors();
	@include fontWeight();
}

strong {
	font-weight: 700;
}


a {
	color: #4D9DE0;

	&:hover {
		color: darken(#4D9DE0, 5%);
	}
}