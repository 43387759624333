.card {
	display: flex;
	flex-direction: column;

	border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

	border-radius: 1rem;

	border: 1px solid #E1E4E8;
}

.cardHeader {
}

.cardHeaderImage {
	width: 100%;
	border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.cardHeaderContent {
	padding: 1rem;
}

.cardContent {
	flex-grow: 1;
	padding: 1rem;
}

.cardFooter {
	border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
	border: 1px solid #E1E4E8;

	div {
		&:first-child {
			border-bottom-left-radius: 1rem;
		}
	}
}
