$actionButtonSize: 2.5rem;

.productImage {
	width: 100%;
}

.container {
	display: grid;
	grid-template-columns: 0.5fr 1.5fr;
	grid-template-rows: 100%;
	gap: 1rem;
	grid-auto-flow: row;
	grid-template-areas:
		"left right";
	width: 90%;

	height: 5rem;
	// margin-left: 0.5rem;
	// margin-right: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 2.5rem;
}

.left { grid-area: left; }

.right {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 0.5fr 2fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-template-areas:
		"title title title"
		"description description description"
		"bottom bottom bottom";
	grid-area: right;
}

.title {
	display: grid; 
	grid-template-columns: 1fr 1.5fr 0.5fr; 
	grid-template-rows: 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
		"titleText titleText titleAction"; 
	grid-area: title; 
}

.titleText { grid-area: titleText; }
.titleAction {
	grid-area: titleAction;

	h5 {
		i {
			vertical-align: middle;
		}	
		&:hover {
			color: #E93541;
			cursor: pointer;
		}
	}
}

.description { grid-area: description; }

.bottom {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-template-areas:
		"a actions";
	grid-area: bottom;

	justify-items: end;
}

.cost {
	grid-area: cost;
	font-size: 20px;
}


.actions {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-template-areas:
		"actionsLeft actionsMiddle actionsRight";
	grid-area: actions;
}

@mixin actionButton {
	text-align: center;
	outline: none;

	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: none;
    box-sizing: border-box;
    display: block;
    font-size: 1em;
    line-height: 1.5;

	background-color: #3B4255;
	color: #FFF;
	width: 100%;
	height: 100%;

	&:hover {
		background-color: #2A2F3C;
		cursor: pointer;
	}
}

.actionsLeft {
	grid-area: actionsLeft;

	width: $actionButtonSize;
	height: $actionButtonSize;
	button {
		@include actionButton();
	}
}

/* Aggressive reset to remove the initial spinner */
input[type="number"] {
	-moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance:none;
	margin: 0;
  }
  input[type="number"]:hover::-webkit-inner-spin-button,
  input[type="number"]:hover::-webkit-outer-spin-button {
   -moz-appearance: none;
	-webkit-appearance: none;
	appearance:none;
	margin:0;
  }

.actionsMiddle {
	grid-area: actionsMiddle;
	
	text-align: center;
	outline: none;

	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: none;
    box-sizing: border-box;
    font-size: 1em;
    line-height: 1.5;

    background-color: lighten(#3B4255, 5%);

    color: white;

	display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

	width: $actionButtonSize;
	height: $actionButtonSize;
}

.spinnerInput {
	width: 100%;
	height: 100%;
	text-align: center;
	outline: none;

	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: none;
    box-sizing: border-box;
    display: block;
    font-size: 1em;
    line-height: 1.5;

    background-color: lighten(#3B4255, 5%);

    color: white;
}

.actionsRight {
	grid-area: actionsRight;

	width: $actionButtonSize;
	height: $actionButtonSize;

	button {
		@include actionButton();
	}
}


/* For presentation only, no need to copy the code below */

// .container * {
// 	// border: 1px solid red;
// 	// position: relative;
// }

// .container *:after {
// 	// content:attr(class);
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	display: grid;
// 	align-items: center;
// 	justify-content: center;
// }

