   
/* Aggressive reset to remove the initial spinner */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance:none;
  margin: 0;
}
input[type="number"]:hover::-webkit-inner-spin-button,
input[type="number"]:hover::-webkit-outer-spin-button {
 -moz-appearance: none;
  -webkit-appearance: none;
  appearance:none;
  margin:0;
}

.container {
	width: 100%;
	display: grid; 
	grid-template-columns: 1fr 1fr 1fr; 
	grid-template-rows: 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
		"left middle right";
	grid-template-rows: 100%; 

  border-radius: inherit;
}

.left { grid-area: left; }
.middle { grid-area: middle; }
.right { grid-area: right; }

.spinnerButton {
	width: 100%;
	height: 100%;
	outline: none;

	cursor: pointer;
    display: block;
    font-size: 1em;
    font-weight: 700;
    padding: 1.1em 1em 1.15em;
    text-align: center;
    text-decoration: none;

	border: none;

	background-color: #3B4255;
  color: white;

  border-radius: inherit;


  &:hover {
    background-color: #2A2F3C;
  }
}

.spinnerInput {
	width: 100%;
	height: 100%;
	text-align: center;
	outline: none;

	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-radius: none;
    box-sizing: border-box;
    display: block;
    font-size: 1em;
    line-height: 1.5;

    background-color: lighten(#3B4255, 5%);

    color: white;
}