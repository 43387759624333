@mixin keyframes( $animationName ) {
    @-webkit-keyframes #{$animationName} {
        @content;
	}
	
    @-moz-keyframes #{$animationName} {
        @content;
	}
	
	@-o-keyframes #{$animationName} {
        @content;
	}
	
    @keyframes #{$animationName} {
        @content;
    }
}